import { makeStyles, Theme } from "@material-ui/core";
import React, { FC } from "react";
import { useParams } from "react-router";
import CrossIcon from "../../assets/icons/CrossIcon";
import LogoIcon from "../../assets/icons/LogoIcon";
import { getHomePageUrl } from "../../utils/hubRoutes";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "40px 24px 46px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      marginTop: 60,
      borderRadius: 20,
      width: 540,
      flexGrow: 0,
      padding: "78px 42px 62px",
      alignSelf: "flex-start",
      boxShadow: "0px 27px 34px 0px rgba(35, 11, 78, 0.31)",
    },
  },
  crossLink: {
    position: "absolute",
    top: 20,
    right: 20,
    fontSize: 16,
  },
  logoLink: {
    alignSelf: "center",
  },
  logo: {
    width: 80,
    height: "auto",
  },
  title: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 24,
    fontSize: 20,
    [theme.breakpoints.up("xs")]: {
      marginTop: 35,
      marginBottom: 34,
      fontSize: 30,
    },
  },
  subTitle: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 42,
    fontSize: 14,
    [theme.breakpoints.up("xs")]: {
      margin: "35px 4px 56px",
    },
  },
}));

interface Props {
  withLoginLink?: boolean;
  title?: string;
  subTitle?: string;
}

export const Layout: FC<Props> = ({ children, withLoginLink, title, subTitle }) => {
  const classes = useStyles();
  const { locale } = useParams<{ locale: string }>();

  return (
    <div className={classes.root}>
      {withLoginLink && (
        <a href={`/${locale}/login`}>
          <CrossIcon className={classes.crossLink} viewBox="0 0 20 20" />
        </a>
      )}
      <a className={classes.logoLink} href={getHomePageUrl(locale)} rel="noopener noreferrer">
        <LogoIcon viewBox="0 0 80 16" className={classes.logo} />
      </a>
      {title && <div className={classes.title}>{title}</div>}
      {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
      {children}
    </div>
  );
};
