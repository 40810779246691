export interface AnalyticsEvent {
  category?: string;
  action?: string;
  label?: string | number | boolean;
  event?: string;
  platform?: string;
  [key: string]: unknown;
}

declare let window: Window & { dataLayer: AnalyticsEvent[] };

export const sendEvent = (args: AnalyticsEvent) => {
  if (!args) return;

  const data = { event: "eventsV2", ...args };

  if (typeof data.label === "boolean") {
    data.label = data.label ? "Yes" : "No";
  }

  try {
    window.dataLayer.push(data);
  } catch (e) {
    console.log("Datalayer push failed, ", e);
  }
};
