import Cookies, { CookieAttributes } from "js-cookie";
import { omit } from "lodash";
import { ACCEPTED_COOKIES, USER_COOKIE } from "../constants/cookie";

const USER_OMIT_ATTR = [
  "fb_conversion",
  "contact",
  "stripe_product_id",
  "stripe_client_reference_id",
  "has_paid",
  "last_login",
  "date_joined",
];

const formatKey = (key: string) => `${key}_v2`;

const getCookieDomain = () => {
  const domain = document.domain;
  const domainSegments = domain.split(".");
  const cookieDomain = domainSegments.slice(1).join(".");
  const isLocal = ["localhost", "127.0.0.1"].includes(domain);
  return isLocal ? domain : `.${cookieDomain}`;
};

export const writeCookie = (
  key: string,
  value: undefined | string | Record<string, unknown>,
  extraOptions?: CookieAttributes
) => {
  const options = {
    domain: getCookieDomain(),
    ...extraOptions,
  };

  const formattedKey = formatKey(key);
  if (typeof value === "undefined") {
    Cookies.remove(formattedKey, options);
  } else {
    Cookies.set(formattedKey, value, options);
  }
};

export const readCookie = (key: string): undefined | string | Record<string, string> => {
  const formattedKey = formatKey(key);
  const value = Cookies.get(formattedKey);
  if (typeof value === "undefined") {
    return undefined;
  }

  try {
    return JSON.parse(value) as string;
  } catch (e) {
    return value;
  }
};

export const acceptCookies = () => {
  writeCookie(ACCEPTED_COOKIES, "true", { expires: 3650 }); // 10 years
};

export const writeUserCookie = (value: Record<string, unknown>, extraOptions?: CookieAttributes) => {
  writeCookie(USER_COOKIE, omit(value, USER_OMIT_ATTR), extraOptions);
};
