import ProcessEnvironment from "../enums/ProcessEnvironment";

export type OsType = "Linux" | "Mac" | "Windows" | "Unknown";

export const OsList: OsType[] = ["Linux", "Mac", "Windows"];

export function isIOS() {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export function isSSR() {
  return typeof window === "undefined" || typeof document === "undefined";
}

export function isPWA() {
  // @ts-ignore
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator["standalone"] ||
    document.referrer.includes("android-app://")
  );
}

export async function isServiceWorkerActive() {
  if (isSSR()) return false;

  const registration = await window.navigator?.serviceWorker?.getRegistration();

  if (!registration?.active) return false;

  return true;
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isDev() {
  return process.env.NX_BUILD === ProcessEnvironment.DEV;
}

export function isStaging() {
  return process.env.NX_BUILD === ProcessEnvironment.STAGING;
}

export function isProduction() {
  return process.env.NX_BUILD === ProcessEnvironment.PRODUCTION;
}

export function isLocalhost() {
  return Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) !== null
  );
}

export const getOs: () => OsType = () => {
  // https://stackoverflow.com/a/19176790/3078890
  const currentOs = OsList.find((os) => window.navigator.userAgent.includes(os));
  return currentOs ?? "Unknown";
};
