import { AnalyticsEvent } from "../utils/analytics/sendEvent";

const ev =
  (category: string, action: string, label: string | number, nonInteractive = false) =>
  (overrides?: { category?: string; label?: string | number; value?: number }): AnalyticsEvent => ({
    category,
    action,
    label,
    value: 0,
    nonInteractive,
    ...overrides,
  });

export const eventsV2 = {
  login: {
    createAccount: ev("Login", "Create account button click", "<organic|free>"),
    forgotPasswordClicked: ev("Login", "Forgot password button click", "<locale>"),
    buttonClicked: ev("Login", "Signin button click", "<google|facebook|email>"),
    success: ev("Login", "Signin success", "<google|facebook|email>", true),
    failure: ev("Login", "Signin Failure", "<google|facebook|email>", true),
  },
  recovery: {
    forgotPasswordSubmit: ev("Login", "Reset password submit click", "<locale>"),
  },
  passwordRecovery: {
    changePasswordClicked: ev("Account", "Change password click", "Profile"),
  },
};
