import { loadGeoInfo } from "@ueni/others-api/api/loadGeoInfo";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Redirect } from "react-router-dom";
import { countryToLocales, DEFAULT_LOCALE } from "../../localization/constants";
import api from "../../services/api";

export const RedirectToSupportedLocale = () => {
  const [locale, setLocale] = useState<null | string>(null);
  const { url } = useRouteMatch();

  const getUserCountry = useCallback(async () => {
    try {
      const geoInfo = await loadGeoInfo(api);
      const countryCode = (geoInfo.data.country_code || "").toLocaleLowerCase();
      const locale = countryToLocales[countryCode] || DEFAULT_LOCALE;

      setLocale(locale);
    } catch (e) {
      setLocale(DEFAULT_LOCALE);
    }
  }, []);

  useEffect(() => {
    getUserCountry();
  }, []);

  if (!locale) {
    return null;
  }

  return <Redirect to={{ pathname: `/${locale}${url}`, search: window.location.search }} />;
};
