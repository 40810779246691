import { resetPassword } from "@ueni/users-api";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { eventsV2 } from "../../../constants/eventsV2";
import { useErrorHandling } from "../../../hooks/useErrorHandling";
import { recoverySchema as validationSchema } from "../../../schema";
import api from "../../../services/api";
import NotificationsManager from "../../../services/notifications";
import { sendEvent } from "../../../utils/analytics/sendEvent";

export const useRecoveryPage = () => {
  const { formatMessage } = useIntl();
  const { handleApiError } = useErrorHandling();
  const locale = useParams()["locale"];
  const initialValues = {
    email: "",
  };
  const onSubmit = useCallback(
    async (data, actions) => {
      sendEvent(eventsV2.recovery.forgotPasswordSubmit({ label: locale }));
      try {
        await resetPassword(api, data);
        actions.resetForm();
        NotificationsManager.show(formatMessage({ id: "recovery.successText" }));
        setTimeout(() => {
          window.location.assign(`${process.env.NX_HUB_BASE_URL}/${locale}`);
        }, 5000);
      } catch (e) {
        handleApiError(e as AxiosError, actions);
      }
    },
    [locale]
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};
