import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 40,
    width: 250,
    borderRadius: 4,
    padding: "2px 10px 2px 2px",
    textAlign: "left",
    border: "none",
    outline: "none",
    cursor: "pointer",
    fontFamily: "'Montserrat', sans-serif",
    "& > *": {
      display: "inline-block",
      verticalAlign: "middle",
    },
  },
  iconWrapper: {
    background: "#fff",
    borderRadius: 2,
    marginRight: 10,
    padding: 9,
    "& svg": {
      verticalAlign: "top",
      fontSize: 18,
    },
  },
  textWrapper: {
    color: "#fff",
    fontWeight: 600,
    fontSize: 12,
    [theme.breakpoints.up("sm")]: {
      padding: "0 0.2rem",
    },
  },
}));

interface Props {
  className?: string;
  icon: ReactNode;
  text: ReactNode;
  onClick: () => void;
}

export const BaseSocialButton = ({ className, text, icon, onClick }: Props) => {
  const classes = useStyles();

  return (
    <button className={clsx(classes.root, className)} onClick={onClick}>
      <div className={classes.iconWrapper}>{icon}</div>
      <div className={classes.textWrapper}>{text}</div>
    </button>
  );
};
