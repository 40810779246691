import { makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import EmailIcon from "../../../assets/icons/EmailIcon";
import { FormikTextField } from "../../../components/form/Formik";
import FormError from "../../../components/form/Formik/FormError";
import { useRecaptchaFormikForm } from "../../../components/form/Formik/hooks/useRecaptchaFormikForm";
import { RecaptchaSubmitButton } from "../../../components/form/Formik/RecaptchaSubmitButton";
import { Layout } from "../../components/Layout";
import { PageHelmet } from "../../components/PageHelmet";
import { useRecoveryPage } from "./useRecoveryPage";

const useStyles = makeStyles({
  form: {
    padding: "0 26px",
    display: "flex",
    flexDirection: "column",
  },
  separator: {
    height: 2,
    backgroundColor: "rgba(61, 4, 155, 0.22)",
  },
  error: {
    marginTop: 10,
  },
  button: {
    alignSelf: "center",
    marginTop: 35,
    marginBottom: 55,
  },
});

export const RecoveryPage = () => {
  const { initialValues, validationSchema, onSubmit } = useRecoveryPage();
  const { formatMessage } = useIntl();
  const { recaptchaButtonRef, onKeyPress } = useRecaptchaFormikForm();
  const classes = useStyles();

  return (
    <Layout
      title={formatMessage({ id: "recovery.forgotPassword" })}
      subTitle={formatMessage({ id: "recovery.subTitle" })}
      withLoginLink
    >
      <PageHelmet path="recovery" />
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        <div className={classes.form} onKeyPress={onKeyPress}>
          <FormikTextField
            fullWidth
            name="email"
            placeholder={formatMessage({ id: "common.email" })}
            InputProps={{
              startAdornment: <EmailIcon viewBox="0 0 15 16" />,
            }}
          />
          <div className={classes.separator} />
          <FormError className={classes.error} />
          <RecaptchaSubmitButton className={classes.button} ref={recaptchaButtonRef}>
            <FormattedMessage id="common.submit" />
          </RecaptchaSubmitButton>
        </div>
      </Formik>
    </Layout>
  );
};
