function extraParams() {
  const env = process.env.NX_BUILD;

  if (env === "prod") {
    return "";
  }

  return `&gtm_auth=JNfn6WpXvPIkXzaOulaZzg&gtm_preview=env-79&gtm_cookies_win=x`;
}

export default (function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l !== "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl + extraParams();
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "GTM-P9XRF82");
