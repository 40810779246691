import { InputAdornment, makeStyles, TextField, Theme } from "@material-ui/core";
import React, { FC, memo } from "react";
import FieldInvalidIcon from "../../../../assets/icons/FieldInvalidIcon";
import { FormTextFieldProps } from "./types";

const useStyles = makeStyles((theme: Theme) => ({
  adorned: {
    fontSize: 15,
    "& svg": {
      fontSize: "inherit",
    },
  },
  input: {
    fontSize: 16,
    [theme.breakpoints.up("xs")]: {
      fontSize: 21,
    },
  },
}));

const FormTextField: FC<FormTextFieldProps> = ({ showError, required, ...props }) => {
  const { InputProps = {}, validationSuccess, ...restProps } = props;
  const { adorned, ...classes } = useStyles();

  return (
    <TextField
      InputProps={{
        ...InputProps,
        required,
        classes,
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start" className={adorned}>
            {showError ? <FieldInvalidIcon viewBox="0 0 14 16" /> : InputProps.startAdornment}
          </InputAdornment>
        ),
      }}
      size="small"
      {...restProps}
    />
  );
};

export default memo(FormTextField);
