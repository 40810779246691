import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-325.000000, -408.000000)" fill="#7ED321" fillRule="nonzero" stroke="#7ED321">
      <g transform="translate(243.000000, 60.000000)">
        <path d="M88.2508673,361.754717 C88.0588493,361.754717 87.8668313,361.676587 87.7198175,361.521922 L83.2193956,356.738454 C82.9268681,356.427529 82.9268681,355.922076 83.2193956,355.61115 C83.511923,355.300225 83.9874676,355.300225 84.279995,355.61115 L88.2493671,359.830169 L98.2193018,349.233194 C98.5118292,348.922269 98.9873738,348.922269 99.2799012,349.233194 C99.5724287,349.544119 99.5724287,350.049572 99.2799012,350.360498 L88.7789168,361.521922 C88.631903,361.678182 88.439885,361.754717 88.247867,361.754717 L88.2508673,361.754717 Z" />
      </g>
    </g>
  </g>,
  "SuccessIcon"
);
