import * as Sentry from "@sentry/react";
import { isProduction } from "@ueni/fe-kit/utils/environment";

Sentry.init({
  dsn: process.env.NX_LOGIN_SENTRY_DSN,
  debug: !isProduction(),
  environment: process.env.NX_BUILD,
  release: `login@${process.env.NX_GIT_COMMIT}`,
  denyUrls: ["localhost", "127.0.0.1"],
});
