import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import get from "lodash/get";

const UENI_USER_COOKIE = "ueniUser_v2";

const convertToJWT = (stringifiedJson: string) => {
  const cookieValue = JSON.parse(stringifiedJson);
  return `JWT ${get(cookieValue, "auth.token", undefined)}`;
};

const getUserToken = () => {
  const ueniUserCookie = Cookies.get(UENI_USER_COOKIE);
  if (ueniUserCookie) {
    return convertToJWT(ueniUserCookie);
  }

  return undefined;
};

const cookieToken = getUserToken();

const fulfillInterceptor = (response: AxiosResponse) => {
  return response;
};

const rejectInterceptor = (error: AxiosError) => {
  return Promise.reject(error);
};

const api = axios.create({
  baseURL: `${process.env.NX_BASE_URL}`,
  headers: {
    authorization: cookieToken,
  },
});

api.interceptors.response.use(fulfillInterceptor, rejectInterceptor);

export default api;
