import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { forwardRef, LegacyRef } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 220,
    fontSize: "1rem",
    height: 48,
    padding: "0 16px",
    backgroundColor: "#FF4A00",
    border: "none",
    borderRadius: 32,
    color: "#fff",
    cursor: "pointer",
    alignSelf: "center",
    textTransform: "uppercase",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    "&:disabled": {
      opacity: 0.5,
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      height: 68,
    },
  },
}));

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button = forwardRef(({ children, className, type = "button", onClick }: ButtonProps, ref) => {
  const classes = useStyles();
  return (
    <button
      className={clsx(classes.root, className)}
      type={type}
      onClick={onClick}
      ref={ref as LegacyRef<HTMLButtonElement>}
    >
      {children}
    </button>
  );
});
