import { exposeMarketingData } from "@ueni/fe-kit/services/marketingData";
// import { getUserSubscriptions, getUserVenues, Venue } from "@ueni/gateway-api";
import {
  getUserData,
  LoginFacebookPayload,
  LoginGooglePayload,
  LoginResponse,
  passwordLogin,
  socialLogin,
} from "@ueni/users-api";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { REDIRECT_COOKIE } from "../../../constants/cookie";
import { eventsV2 } from "../../../constants/eventsV2";
import { useQuery } from "../../../hooks";
import { useErrorHandling } from "../../../hooks/useErrorHandling";
import { loginSchema as validationSchema } from "../../../schema";
import api from "../../../services/api";
import { acceptCookies, readCookie, writeCookie, writeUserCookie } from "../../../services/cookieStorage";
import { sendEvent } from "../../../utils/analytics/sendEvent";
import { getHubUrl } from "../../../utils/hubRoutes";

interface FormValues {
  email: string;
  password: string;
}

export const useLoginPage = () => {
  const locale = useParams()["locale"];
  const query = useQuery();
  const { handleApiError } = useErrorHandling();
  const redirect_url = query.get("redirect_url");
  const passToken = query.get("pass_token");
  const [submitFailed, setSubmitFailed] = useState<null | boolean>(null);
  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const afterLogin = useCallback(
    async (data: LoginResponse, label: string) => {
      sendEvent(eventsV2.login.success({ label }));
      const { token, expiration, user } = data;
      exposeMarketingData("user", user as any);
      acceptCookies();
      writeUserCookie({ auth: { token, expiration }, ...user });
      let redirectUrl = redirect_url;
      if (!redirectUrl) {
        redirectUrl = readCookie(REDIRECT_COOKIE) as string;
      }
      writeCookie(REDIRECT_COOKIE, undefined);
      if (redirectUrl) {
        const url = passToken ? `${redirectUrl}?token=${token}` : redirectUrl;
        return window.location.assign(decodeURIComponent(url));
      }

      window.location.assign(`${getHubUrl(user.locale)}?logged_user=1`);

      // try {
      //   const [{ data: venuesData }, { data: subsData }] = await Promise.all([
      //     getUserVenues(api, token),
      //     getUserSubscriptions(api, token),
      //   ]);
      //
      //   const venue: Venue = venuesData.results[0] || ({} as Venue);
      //   const isFinished = ["testaccount", "finished", "unsupported_finished"].includes(venue.content_creation_status);
      //   const mainSubscription = subsData.subscriptions.find(({ product_type }) => product_type === "main");
      //   const isPaid = mainSubscription && !mainSubscription.plan_code.includes("_free_");
      //
      //   if (isPaid && isFinished) {
      //     window.location.assign(getProFeaturesUrl(user.locale, venue.slug));
      //     return;
      //   }
      //   window.location.assign(getHubUrl(user.locale));
      // } catch (e) {
      //   window.location.assign(getHubUrl(user.locale));
      // }
    },
    [passToken, redirect_url]
  );

  const onPasswordLogin = useCallback(
    async (payload, actions) => {
      const label = "email";
      sendEvent(eventsV2.login.buttonClicked({ label }));
      try {
        const { data } = await passwordLogin(api, payload);
        setSubmitFailed(false);
        return afterLogin(data, label);
      } catch (e) {
        sendEvent(eventsV2.login.failure({ label }));
        setSubmitFailed(true);
        handleApiError(e as AxiosError, actions);
        return;
      }
    },
    [afterLogin]
  );

  const onResolve = useCallback(
    async (payload: LoginGooglePayload | LoginFacebookPayload) => {
      const label = payload.method;
      sendEvent(eventsV2.login.buttonClicked({ label }));
      try {
        const { data } = await socialLogin(api, payload);
        return afterLogin(data, label);
      } catch (e) {
        sendEvent(eventsV2.login.failure({ label }));
        handleApiError(e as AxiosError);
        return;
      }
    },
    [afterLogin]
  );

  const sendSignUpClickEvent = useCallback(() => sendEvent(eventsV2.login.createAccount({ label: "???" })), []);
  const sendForgotClickEvent = useCallback(
    () => sendEvent(eventsV2.login.forgotPasswordClicked({ label: locale })),
    [locale]
  );

  const redirectIfAuthorized = useCallback(async () => {
    try {
      const { data } = await getUserData(api);
      return window.location.assign(getHubUrl(data.locale));
    } catch (e) {
      // all good, user is not authorized
    }
  }, []);

  useEffect(() => {
    if (passToken) return;

    redirectIfAuthorized();
  }, [passToken]);

  return {
    locale,
    initialValues,
    validationSchema,
    onPasswordLogin,
    submitFailed,
    onResolve,
    sendSignUpClickEvent,
    sendForgotClickEvent,
  };
};
