import { ReactNode } from "react";
import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./notifications.scss";

class Notifications {
  show = (message: string | ReactNode, options?: ToastOptions) => {
    toast(message, options);
  };

  error = (message: string | ReactNode, options?: ToastOptions) => {
    toast.error(message, options);
  };
}

const NotificationsManager = new Notifications();

export default NotificationsManager;
