import { makeStyles, Theme } from "@material-ui/core";
import { GoogleLoginButton } from "@ueni/fe-kit/components/social/GoogleLogin";
import clsx from "clsx";
import { Form, Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import EmailIcon from "../../../assets/icons/EmailIcon";
import PasswordIcon from "../../../assets/icons/PasswordIcon";
import SuccessIcon from "../../../assets/icons/SuccessIcon";
import { Button } from "../../../components/form/Button";
import { FormikTextField } from "../../../components/form/Formik";
import FormError from "../../../components/form/Formik/FormError";
import { getSignUpUrl } from "../../../utils/hubRoutes";
import { Layout } from "../../components/Layout";
import { PageHelmet } from "../../components/PageHelmet";
import { FacebookAuthButton } from "./components/FacebookAuthButton/FacebookAuthButton";
import { useLoginPage } from "./useLoginPage";

const useStyles = makeStyles((theme: Theme) => ({
  buttonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    maxWidth: "100%",
    gap: 10,
    flexGrow: 0,
  },
  separator: {
    height: 2,
    backgroundColor: "rgba(61, 4, 155, 0.22)",
  },
  form: {
    padding: "0 26px",
    display: "flex",
    flexDirection: "column",
  },
  text: {
    marginTop: 32,
    marginBottom: 12,
  },
  formError: {
    minHeight: 20,
    marginBottom: 18,
  },
  button: {
    alignSelf: "center",
  },
  forgotLink: {
    color: theme.palette.text.primary,
    alignSelf: "center",
    marginTop: 48,
    fontWeight: 700,
    textDecoration: "underline",
    [theme.breakpoints.up("xs")]: {
      marginTop: 30,
    },
  },
  forgotLinkHighlight: {
    fontSize: 19,
    color: "#FF4A00",
  },
  signUpLink: {
    color: theme.palette.text.primary,
    alignSelf: "center",
    marginTop: 18,
    fontWeight: 500,
    textDecoration: "none",
  },
}));

export const LoginPage = () => {
  const {
    initialValues,
    locale,
    validationSchema,
    onPasswordLogin,
    submitFailed,
    onResolve,
    sendSignUpClickEvent,
    sendForgotClickEvent,
  } = useLoginPage();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const isSubmitSuccess = submitFailed === false;

  return (
    <Layout title={formatMessage({ id: "login.welcome" })}>
      <PageHelmet path="login" />
      <div className={classes.buttonsWrapper}>
        <FacebookAuthButton onResolve={onResolve} />
        <GoogleLoginButton onResolve={onResolve} googleClientId={process.env.NX_LOGIN_GOOGLE_CLIENT_ID!} />
      </div>
      <Formik onSubmit={onPasswordLogin} initialValues={initialValues} validationSchema={validationSchema}>
        <Form className={classes.form}>
          <div className={classes.text}>
            <FormattedMessage id="login.password" />
          </div>
          <FormikTextField
            fullWidth
            name="email"
            placeholder={formatMessage({ id: "common.email" })}
            InputProps={{
              startAdornment: isSubmitSuccess ? <SuccessIcon viewBox="0 0 18 15" /> : <EmailIcon viewBox="0 0 15 16" />,
            }}
            submitFailed={!!submitFailed}
          />
          <div className={classes.separator} />
          <FormikTextField
            fullWidth
            name="password"
            placeholder={formatMessage({ id: "common.password" })}
            type="password"
            InputProps={{
              startAdornment: isSubmitSuccess ? (
                <SuccessIcon viewBox="0 0 18 15" />
              ) : (
                <PasswordIcon viewBox="0 0 14 18" />
              ),
            }}
            submitFailed={!!submitFailed}
          />
          <FormError className={classes.formError} />
          <Button type="submit" className={classes.button}>
            <FormattedMessage id="common.signIn" />
          </Button>
        </Form>
      </Formik>
      <Link
        to={`/${locale}/recovery`}
        className={clsx(classes.forgotLink, submitFailed && classes.forgotLinkHighlight)}
        onClick={sendForgotClickEvent}
      >
        <FormattedMessage id="login.forgotPassword" />
      </Link>
      <a href={getSignUpUrl(locale)} className={classes.signUpLink} onClick={sendSignUpClickEvent}>
        <FormattedMessage id="login.signUp" />
      </a>
    </Layout>
  );
};
