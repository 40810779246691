import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC, memo, useCallback, useState } from "react";
import PasswordHideIcon from "../../../../assets/icons/PasswordHideIcon";
import PasswordShowIcon from "../../../../assets/icons/PasswordShowIcon";
import ValidationMarks from "../ValidationMarks";
import { FormPasswordTextFieldProps } from "./types";

const useStyles = makeStyles({
  icon: {
    fill: "rgb(198, 198, 198)",
    transition: "0.3s ease fill",
    ".Mui-focused &": {
      fill: "#4B4B4B",
    },
    ".MuiInputBase-root:hover:not(.Mui-disabled) &": {
      fill: "#4B4B4B",
    },
  },
  iconWrap: {
    ".Mui-disabled &": {
      pointerEvents: "none",
    },
    ".MuiInputBase-root:not(.Mui-disabled) &": {
      cursor: "pointer",
    },
    borderLeft: "1px solid rgb(198, 198, 198)",
    paddingLeft: 8,
    paddingRight: 7,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.3s ease border-color",
    ".MuiInputBase-root:hover:not(.Mui-disabled):not(.Mui-error) &": {
      borderLeftColor: "rgb(92, 92, 92)",
    },
    ".Mui-error &": {
      borderLeftColor: "#f44336",
    },
  },
});

const FormPasswordField: FC<FormPasswordTextFieldProps> = ({ validationSuccess, required, showError, ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const classes = useStyles();

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      InputProps={{
        required,
        endAdornment: (
          <InputAdornment position="end">
            <ValidationMarks error={props.error} success={validationSuccess} />
            <div className={classes.iconWrap} onClick={handleClickShowPassword}>
              {showPassword ? (
                <PasswordShowIcon className={classes.icon} viewBox="0 0 21 11" />
              ) : (
                <PasswordHideIcon className={classes.icon} viewBox="0 0 21 21" />
              )}
            </div>
          </InputAdornment>
        ),
      }}
      {...props}
      variant="outlined"
      size="small"
    />
  );
};

export default memo(FormPasswordField);
