import { useFormikContext } from "formik";
import React, { forwardRef, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import Recaptcha from "react-google-invisible-recaptcha";
import { Button } from "../../Button";

interface Props {
  name?: string;
  className?: string;
  children?: ReactNode;
}

export const RecaptchaSubmitButton = forwardRef(
  ({ children, className, name = "g-recaptcha-response" }: Props, ref) => {
    const refRecaptcha = useRef<any>(null);
    const [resolved, setResolved] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [cb, setCb] = useState(false);
    const { dirty, setFieldValue, handleSubmit } = useFormikContext();

    const onClick = useCallback(() => {
      if (resolved) {
        return handleSubmit();
      } else if (loaded) {
        refRecaptcha.current?.callbacks.execute();
      } else {
        setCb(true);
      }
    }, [refRecaptcha, loaded, resolved, handleSubmit, setCb]);

    useEffect(() => {
      if (cb && loaded) {
        refRecaptcha.current?.callbacks.execute();
        setCb(false);
      }
    }, [cb, loaded]);

    useEffect(() => {
      if (!dirty) {
        setCb(false);
        setResolved(false);
        setLoaded(false);
      }
    }, [dirty]);

    const onLoaded = useCallback(() => {
      setLoaded(true);
    }, [setLoaded]);

    const onResolved = useCallback(() => {
      const token = refRecaptcha.current?.callbacks.getResponse();
      setFieldValue(name, token);
      setResolved(true);
      return handleSubmit();
    }, [refRecaptcha, setFieldValue, handleSubmit, name]);

    return (
      <div>
        <Button onClick={onClick} className={className} ref={ref}>
          {children}
        </Button>
        {dirty && (
          <Recaptcha
            onLoaded={onLoaded}
            onResolved={onResolved}
            ref={refRecaptcha}
            sitekey={process.env.NX_RECAPTCHA_KEY}
          />
        )}
      </div>
    );
  }
);
