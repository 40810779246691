import * as yup from "yup";

export const passwordRecoverySchema = yup.object({
  password: yup.string().required().min(8),
  passwordConfirmation: yup
    .string()
    .required()
    .min(8)
    .oneOf([yup.ref("password")], "passwordRecovery.errors.match"),
});
