export const DEFAULT_LOCALE = "en-us";

export const countryToLocales = {
  gb: "en-gb",
  us: "en-us",
  in: "en-in",
  ca: "en-ca",
  es: "es-es",
  mx: "es-mx",
  co: "es-co",
  cl: "es-cl",
  pe: "es-pe",
  br: "pt-br",
};

export const SUPPORTED_LOCALES = Object.values(countryToLocales);

export const BeErrorsMap: Record<string, string> = {
  "This password is too common.": "common.be.errors.passwordCommon",
  "This password is entirely numeric.": "common.be.errors.passwordNumeric",
  "Error decoding signature.": "common.be.errors.signatureDecoding",
  "Unable to log in with provided credentials.": "common.be.errors.unableToLogin",
  "User account is disabled.": "common.be.errors.emailDisabled",
  "Your Facebook email is not registered with us. Please sign up first.": "common.be.errors.facebookEmailNotRegistered",
  "Network Error": "common.be.errors.networkError",
};
