import { useCallback, useRef } from "react";

export const useRecaptchaFormikForm = () => {
  const recaptchaButtonRef = useRef<HTMLButtonElement>();

  const onKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        recaptchaButtonRef?.current?.click();
      }
    },
    [recaptchaButtonRef]
  );

  return {
    recaptchaButtonRef,
    onKeyPress,
  };
};
