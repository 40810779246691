import { createTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { muiBreakpoints } from "./breakpoints";

const breakpoints = createBreakpoints({ values: muiBreakpoints });

export const muiTheme = createTheme({
  typography: {
    fontFamily: "'Lato', sans-serif",
  },
  palette: {
    text: {
      primary: "#333333",
    },
  },
  overrides: {
    MuiTouchRipple: {
      root: {
        display: "none",
      },
    },
    MuiFormControl: {
      root: {
        marginTop: 8,
        marginBottom: 8,
      },
    },
    MuiTextField: {
      root: {
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          color: "rgb(75, 75, 75)",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgb(198, 198, 198)",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12,
        fontWeight: 500,
        "&.Mui-disabled": {
          color: "rgb(198, 198, 198)",
        },
      },
    },
    MuiSelect: {
      outlined: {
        padding: 10,
        "&.MuiSelect-outlined": {
          paddingRight: 40,
        },
      },
    },
    MuiInputBase: {
      input: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12,
        fontWeight: 500,
        "&:-webkit-autofill": {
          "-webkit-box-shadow": "0 0 0px 1000px transparent inset",
        },
      },
      root: {
        minHeight: 40,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12,
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 1,
        },
        "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgb(92, 92, 92)",
          borderWidth: 1,
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgb(198, 198, 198)",
        },
      },
      notchedOutline: {
        transition: ".3s ease border-color",
        fontSize: 12,
        borderColor: "rgb(198, 198, 198)",
        color: "rgb(75, 75, 75)",
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiInputLabel: {
      outlined: {
        "&.MuiInputLabel-marginDense": {
          transform: "translate(14px, 14px) scale(1)",
        },
        "&.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 14,
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 10,
        fontWeight: 500,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiCheckbox: {
      root: {
        color: "rgb(151, 151, 151)",
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: "'Montserrat', sans-serif",
      },
    },
    MuiContainer: {
      root: {
        [breakpoints.up("lg")]: {
          paddingRight: 30,
          paddingLeft: 30,
        },
      },
    },
  },
  breakpoints,
});
