import { setLocale } from "yup";

setLocale({
  mixed: {
    default: "common.errors.required",
    required: "common.errors.required",
  },
  string: {
    min: ({ min }) => ({ id: "common.errors.stringMinLimit", values: { min } }),
    max: ({ max }) => ({ id: "common.errors.stringMaxLimit", values: { max } }),
    email: "common.errors.email",
  },
  number: {
    min: ({ min }) => ({ id: "common.errors.numberMinLimit", values: { min } }),
    max: ({ max }) => ({ id: "common.errors.numberMaxLimit", values: { max } }),
  },
});
