import { AxiosInstance } from "axios";
import { GeoInfo } from "../types/GeoInfo";

export async function loadGeoInfo(api: AxiosInstance) {
  return await api.get<GeoInfo>("/api/geo-info/v1/geo/");
}

export async function loadGeoInfoByIpAddress(api: AxiosInstance, ipAddress: string) {
  return await api.get<GeoInfo>(`/api/geo-info/v1/geo/${ipAddress}/`);
}
