/**
 *
 * Facebook Login/logout API wrapped in a React Component
 *
 * - It can be included more than once in an app. Rather than loading
 * FB on app start, load it only when button shows.
 * - It's OK to put this in a component that re-mounts, but it needs
 * to be mouted when button is used.
 *
 *
 * The flow is:
 * - Init by including FbApi in React (componentDidMount)
 * - `window.FB` is loaded
 * - Click on `Continue with` button calls `fbLogin`
 * - `doLogin` makes Facebook pop up
 * - `onFbLoginFinished` gets called with authResp
 * - `onFbLoginSuccess` will call signup or login action
 * - calling `fbLogout` will ask FB to log user out (it might not oblige)
 */
import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import NotificationsManager from "./notifications";

// reference to the currently mounted
let onFbLoginFinished;
let onFbLoginSuccess;

/**
 * It's a bit silly to keep a status that might be out-of-date.
 * Doing this due to the annoying callback nature of FB api.
 *
 * Login popup needs to get triggered by user interaction -
 * when it happens in a callback, browsers tend to block the popup.
 *
 * So, we cannot load fb sdk and getLoginStatus just after click
 * (only workaround I can think of is to use timeouts)
 */
let lastFbLoginStatus;

const doLogin = () => {
  if (lastFbLoginStatus && lastFbLoginStatus.status === "connected" && lastFbLoginStatus.authResponse) {
    onFbLoginFinished(lastFbLoginStatus);
  } else {
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    window.FB.login(onFbLoginFinished, { scope: "public_profile,email" });
  }
};

const refreshFbLoginStatus = () => {
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  window.FB.getLoginStatus((r) => {
    lastFbLoginStatus = r;
  });
};

export function fbLogout() {
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  if (window.FB && window.FB.getAccessToken()) {
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    window.FB.logout((response) => {
      // Note: this might just not get called (and then user stays logged in)
      // Not really sure why this *sometimes* happens, but maybe because of:
      // https://developers.facebook.com/docs/facebook-login/web#logout
      // A person logs into Facebook, then logs into your app.
      // Upon logging out from your app, the person is still logged into Facebook.

      refreshFbLoginStatus(); // refresh in case of subsequent login

      // logger.log('logout', response)
    });
  }
}

export function fbLogin(onSuccess) {
  onFbLoginSuccess = onSuccess;
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  if (window.FB) {
    doLogin();
  } else {
    // for users that are quicker than their network connection
    setTimeout(() => {
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line
      if (window.FB) {
        doLogin();
      }
      // if still not loaded - give up
    }, 500);
  }
}

class FbApi extends React.Component<WrappedComponentProps> {
  onFbLoginFinished(authResp) {
    const message = this.props.intl.formatMessage({ id: "login.facebookEmailFail" });
    if (authResp && authResp.status === "connected") {
      // check if user provided email
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line
      window.FB.api("/me", { fields: "name,first_name,last_name,email" }, (response) => {
        if (!response.email) {
          NotificationsManager.error(message);
          // delete our facebook app so user can try again
          // eslint-disable-next-line
          // @ts-ignore
          // eslint-disable-next-line
          window.FB.api("/me/permissions", "DELETE");
        } else {
          const payload = {
            user_id: authResp.authResponse.userID,
            access_token: authResp.authResponse.accessToken,
            method: "facebook",
          };
          if (onFbLoginSuccess) {
            onFbLoginSuccess(payload);
          }
        }
      });
    }
  }

  componentDidMount() {
    onFbLoginFinished = this.onFbLoginFinished.bind(this);
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    if (!window.fbAsyncInit) {
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line
      window.fbAsyncInit = () => {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        window.FB.init({
          appId: process.env.NX_LOGIN_FACEBOOK_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: "v16.0",
        });

        // FB is now ready
        refreshFbLoginStatus();
      };

      // we nedd to load SDK first
      // doLogin will get called when it's loaded

      /* eslint-disable */
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        // @ts-ignore
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      /* eslint-enable */
    } else {
      refreshFbLoginStatus();
    }
  }

  render() {
    return null;
  }
}

export default injectIntl(FbApi);
