import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.304291214" strokeLinecap="round">
    <g transform="translate(-337.000000, -466.000000)" stroke="#59617B" strokeWidth="2">
      <g transform="translate(253.000000, 60.000000)">
        <g transform="translate(85.000000, 407.000000)">
          <path d="M10,15.9433962 L2,15.9433962 C0.8954296,15.9433962 0,14.9916483 0,13.8176101 L0,7.44025157 L12,7.44025157 L12,13.8176101 C12,14.9916483 11.1045685,15.9433962 10,15.9433962 Z" />
          <path d="M0.5,6.11163522 C0.5,2.88302616 2.9624329,0.26572327 6,0.26572327 C9.0375671,0.26572327 11.5,2.88302616 11.5,6.11163522" />
        </g>
      </g>
    </g>
  </g>,
  "PasswordIcon"
);
