import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <g transform="translate(-744.000000, -78.000000)" stroke="#CDCFD7" strokeWidth="3">
      <g transform="translate(746.000000, 80.000000)">
        <path d="M0.363636364,0.363636364 L15.9112727,15.9112727" />
        <path
          d="M0.363636364,0.363636364 L15.9112727,15.9112727"
          transform="translate(8.000000, 8.000000) scale(1, -1) translate(-8.000000, -8.000000)"
        />
      </g>
    </g>
  </g>,
  "CrossIcon"
);
