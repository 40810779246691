import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { FC, useCallback, useEffect, useRef } from "react";

declare global {
  interface Window {
    google: any;
    onGoogleLibraryLoad: any;
  }
}

interface LoginGooglePayload {
  token_id: string;
  method: "google";
}

interface Props {
  onResolve(p: LoginGooglePayload): void;
  className?: string;
  googleClientId: string;
}

const useStyles = makeStyles({
  root: {
    width: 250,
    display: "block",
    height: 40,
  },
});
/*
  you need to load GSI client in you app
  <script
    async="async"
    defer="defer"
    src="https://accounts.google.com/gsi/client"
  />
 */

export const GoogleLoginButton: FC<Props> = (props) => {
  const { onResolve, googleClientId, className } = props;
  const ref = useRef(null);
  const classes = useStyles();

  const finishGoogleLogin = useCallback(
    (response) => {
      const payload = {
        token_id: response.credential,
        method: "google" as const,
      };

      onResolve(payload);
    },
    [onResolve]
  );

  const renderButton = useCallback(() => {
    try {
      window.google.accounts.id.renderButton(ref.current, {
        theme: "filled_blue",
        size: "large",
        width: 250,
        text: "continue_with",
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const initializeGoogleButton = useCallback(() => {
    window.google.accounts.id.initialize({
      client_id: googleClientId,
      callback: finishGoogleLogin,
    });
    renderButton();
  }, [renderButton, finishGoogleLogin, googleClientId]);

  useEffect(() => {
    if (!window.google) {
      window.onGoogleLibraryLoad = initializeGoogleButton;
    } else {
      initializeGoogleButton();
    }
  }, []);

  return (
    <span className={clsx(classes.root, className)}>
      <div ref={ref} />
    </span>
  );
};
