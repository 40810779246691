import { TextFieldProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useField, useFormikContext } from "formik";
import { map } from "lodash";
import React, { ComponentType, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

const useStyles = makeStyles({
  error: {
    display: "block",
  },
});

function withField<P extends TextFieldProps & { submitFailed?: boolean }>(Component: ComponentType<P>) {
  return (props: P) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const [validationSuccess, setValidationSuccess] = useState(false);

    const { name = "", submitFailed, ...rest } = props;
    const [{ value, ...field }] = useField(name);
    const form = useFormikContext();
    const fieldErrors = useMemo(() => form.errors[name] || [], [form.errors, name]);
    const errors = useMemo(() => {
      return (Array.isArray(fieldErrors) ? fieldErrors : [fieldErrors]).map((error) => {
        return typeof error === "string" ? formatMessage({ id: error }) : formatMessage({ id: error.id }, error.values);
      });
    }, [fieldErrors, formatMessage]);
    const isTouched = form.touched[name];
    const hasError = Boolean(Array.isArray(errors) ? errors.length : errors);
    const showError = hasError && isTouched;

    useEffect(() => {
      if (!hasError && isTouched) {
        setValidationSuccess(true);
      } else {
        setValidationSuccess(false);
      }
    }, [hasError, isTouched]);

    return (
      <Component
        value={value}
        error={showError}
        helperText={
          showError && (
            <>
              {map(errors, (error) => (
                <span className={classes.error} key={error}>
                  {error}
                </span>
              ))}
            </>
          )
        }
        validationSuccess={validationSuccess}
        showError={showError || submitFailed}
        {...field}
        {...(rest as P)}
      />
    );
  };
}

export default withField;
