import { AxiosInstance } from "axios";
import { LoginResponse } from "../types";
import { ChangePasswordPayload } from "../types/changePassword";

export async function changePassword(api: AxiosInstance, payload: ChangePasswordPayload, token: string) {
  const headers = {
    authorization: `JWT ${token}`,
  };

  return await api.post<LoginResponse>("api/users/v1/users/change_password/", payload, { headers });
}
