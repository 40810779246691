import { makeStyles } from "@material-ui/core";
import { LoginFacebookPayload } from "@ueni/users-api";
import clsx from "clsx";
import React from "react";
import { FormattedMessage } from "react-intl";
import FacebookIcon from "../../../../../assets/icons/FacebookIcon";
import FbApi from "../../../../../services/FbApi";
import { BaseSocialButton } from "../BaseSocialButton";
import { useFacebookAuthButton } from "./useFacebookAuthButton";

const useStyles = makeStyles({
  root: {
    background: "#3B5EA7",
  },
});

interface Props {
  className?: string;
  onResolve: (data: LoginFacebookPayload) => Promise<void>;
}

export const FacebookAuthButton = ({ className, onResolve }: Props) => {
  const classes = useStyles();
  const { onClick } = useFacebookAuthButton({ onResolve });

  return (
    <>
      <BaseSocialButton
        icon={<FacebookIcon viewBox="0 0 13 23" />}
        text={<FormattedMessage id="login.facebook" />}
        // @ts-ignore
        onClick={onClick}
        className={clsx(classes.root, className)}
      />
      <FbApi />
    </>
  );
};
