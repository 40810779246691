import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { SUPPORTED_LOCALES } from "../../localization/constants";

interface Props {
  path: string;
}

export const PageHelmet: FC<Props> = ({ path }) => {
  const { locale } = useParams<{ locale: string }>();

  return (
    <Helmet>
      {SUPPORTED_LOCALES.map((locale) => (
        <link rel="alternate" hrefLang={locale} key={locale} href={`${process.env.NX_BASE_URL}/${locale}/${path}`} />
      ))}
      <link rel="alternate" hrefLang="en" href={`${process.env.NX_BASE_URL}/en-us/${path}`} />
      <link rel="alternate" hrefLang="es" href={`${process.env.NX_BASE_URL}/es-es/${path}`} />
      <link rel="alternate" hrefLang="pt" href={`${process.env.NX_BASE_URL}/pt-br/${path}`} />
      <link rel="alternate" hrefLang="x-default" href={`${process.env.NX_BASE_URL}/${path}`} />
      <link href={`${process.env.NX_BASE_URL}/${locale}/${path}`} rel="canonical" />
    </Helmet>
  );
};
