import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { RedirectToSupportedLocale } from "./app/components/RedirectToSupportedLocale";
import { ROOT_ROUTE } from "./app/constants";
import { App } from "./app/index";
import "./assets/styles/global.scss";
import { AppIntlProvider } from "./localization/intlHelpers";
import reportWebVitals from "./reportWebVitals";
import "./services/sentry";
import { muiTheme } from "./theme/mui";
import "./utils/analytics/gtmInit";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        transition={Slide}
        closeOnClick={false}
        closeButton={false}
        draggable={false}
        hideProgressBar
        newestOnTop
      />
      <CssBaseline />
      <Router>
        <Switch>
          <Route path={ROOT_ROUTE}>
            <AppIntlProvider>
              <App />
            </AppIntlProvider>
          </Route>
          <Route path="*">
            <RedirectToSupportedLocale />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("login-app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
