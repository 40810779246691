import { changePassword } from "@ueni/users-api";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useParams } from "react-router";
import { eventsV2 } from "../../../constants/eventsV2";
import { useErrorHandling } from "../../../hooks/useErrorHandling";
import { passwordRecoverySchema as validationSchema } from "../../../schema";
import api from "../../../services/api";
import { writeUserCookie } from "../../../services/cookieStorage";
import { sendEvent } from "../../../utils/analytics/sendEvent";
import { getHubUrl } from "../../../utils/hubRoutes";

export const usePasswordRecoveryPage = () => {
  const requestToken = useParams()["token"];
  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };
  const { handleApiError } = useErrorHandling();
  const onSubmit = useCallback(
    async ({ password }, actions) => {
      sendEvent(eventsV2.passwordRecovery.changePasswordClicked());
      try {
        const { data } = await changePassword(api, { password }, requestToken);
        const { token, expiration, user } = data;
        writeUserCookie({ auth: { token, expiration }, ...user });
        window.location.assign(getHubUrl(user.locale));
      } catch (e) {
        handleApiError(e as AxiosError, actions);
        return;
      }
    },
    [requestToken]
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};
