import { User, Venue } from "@ueni/phoenix-api/types";

const EXPOSED_KEY = "__MARKETING_DATA__";

interface MarketingData {
  venue?: Venue;
  user?: User;
}

const marketingData: MarketingData = {};
window[EXPOSED_KEY] = marketingData;

export function exposeMarketingData<K extends keyof MarketingData>(key: K, data: MarketingData[K]) {
  marketingData[key] = data;
}

export default marketingData;
