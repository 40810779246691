import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useField } from "formik";
import React, { FC, memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { getErrorIntlKey } from "../../../../localization/utils";

interface Props {
  className?: string;
}

const useStyles = makeStyles({
  root: {
    fontSize: 14,
    color: "rgb(255, 27, 27)",
  },
});

const FormError: FC<Props> = ({ className }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [, meta] = useField("non_field_errors");
  const { error = [] } = meta;

  const errors = useMemo(() => {
    return (Array.isArray(error) ? error : [error]).slice(0, 1).map((message) => {
      return formatMessage({ id: getErrorIntlKey(message) });
    });
  }, [error, formatMessage]);

  return (
    <div className={clsx(classes.root, className)}>
      {errors.map((errorText) => (
        <div key={errorText}>{errorText}</div>
      ))}
    </div>
  );
};

export default memo(FormError);
