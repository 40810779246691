import { makeStyles } from "@material-ui/core";
import React from "react";
import { useRouteMatch } from "react-router";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import bg from "../assets/images/background.jpg";
import { LoginPage } from "./routes/login";
import { PasswordRecoveryPage } from "./routes/password-recovery";
import { RecoveryPage } from "./routes/recovery";

const useStyles = makeStyles({
  app: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    alignSelf: "center",
    height: "100%",
    width: "100%",
    justifyContent: "center",
  },
});

export const App = () => {
  const { path, params } = useRouteMatch();
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <Router>
        <Switch>
          <Route exact path={`${path}/login`}>
            <LoginPage />
          </Route>
          <Route exact path={`${path}/recovery`}>
            <RecoveryPage />
          </Route>
          <Route exact path={`${path}/password-recovery/:token`}>
            <PasswordRecoveryPage />
          </Route>
          <Redirect to={{ pathname: `/${params["locale"]}/login`, search: window.location.search }} />
        </Switch>
      </Router>
    </div>
  );
};
