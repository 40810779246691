import { AxiosInstance } from "axios";
import { LoginFacebookPayload, LoginGooglePayload, LoginPasswordPayload, LoginResponse } from "../types/login";

export async function passwordLogin(api: AxiosInstance, payload: LoginPasswordPayload) {
  return await api.post<LoginResponse>("api/users/v1/auth/login/", payload);
}

export async function socialLogin(
  api: AxiosInstance,
  { method, ...payload }: LoginGooglePayload | LoginFacebookPayload
) {
  return await api.post<LoginResponse>(`api/users/v1/auth/login/${method}/`, payload);
}
