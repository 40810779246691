import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
    <g transform="translate(-326.000000, -408.000000)" stroke="#FF4A00" strokeWidth="3">
      <g transform="translate(243.000000, 60.000000)">
        <g transform="translate(84.000000, 350.000000)">
          <path d="M0.5,0.5 L11.3961407,11.8129671" />
          <path
            d="M0.5,0 L11.3961407,11.8129671"
            transform="translate(6.000000, 6.000000) scale(1, -1) translate(-6.000000, -6.000000) "
          />
        </g>
      </g>
    </g>
  </g>,
  "FieldInvalidIcon"
);
