import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(-483.000000, -138.000000)" fill="#333333" fillRule="nonzero">
      <g transform="translate(253.000000, 60.000000)">
        <g transform="translate(230.000000, 78.000000)">
          <path d="M13.5211268,0 L13.5211268,7.99001248 C13.5211268,10.5707865 11.1739437,12.78402 8.45070423,12.78402 C5.72746479,12.78402 3.38028169,10.5707865 3.38028169,7.99001248 L3.38028169,0 L0,0 L0,7.99001248 L0,7.99001248 L0,8.00998752 C0,12.4227496 3.78350916,16 8.45070423,16 C13.1178993,16 16.9014085,12.4227496 16.9014085,8.00998752 L16.9014085,7.99001248 L16.9014085,7.99001248 L16.9014085,0 L13.5211268,0 Z" />
          <polygon points="80 0 80 16 76.6197183 16 76.6197183 0" />
          <polygon points="63.0985915 0 63.0985915 12 52.9577465 0 49.5774648 0 49.5774648 16 52.9577465 16 52.9577465 4 63.0985915 16 66.4788732 16 66.4788732 0" />
          <polygon points="40.5633803 3.2 40.5633803 0 27.0422535 0 27.0422535 12.8 27.0422535 16 30.4225352 16 40.5633803 16 40.5633803 12.8 30.4225352 12.8 30.4225352 9.6 35.4929577 9.6 35.4929577 6.4 30.4225352 6.4 30.4225352 3.2" />
        </g>
      </g>
    </g>
  </g>,
  "LogoIcon"
);
