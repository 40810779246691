import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g stroke="none" strokeWidth="1" fillRule="evenodd">
    <g transform="translate(-269.000000, -645.000000)" fillRule="nonzero">
      <g transform="translate(20.000000, 615.000000)">
        <g transform="translate(249.000000, 30.000000)">
          <path d="M19.833,5.05712851 C19.7922691,5.00553012 18.814506,3.77896386 17.1382209,2.5474498 C14.898241,0.90173494 12.4609237,0.0318514056 10.0897831,0.0318514056 C7.71873092,0.0318514056 5.28141365,0.90173494 3.04134538,2.5474498 C1.36510442,3.77891968 0.387341365,5.00553012 0.346566265,5.05712851 L0,5.4954498 L0.346610442,5.93381526 C0.387385542,5.98541365 1.36514859,7.2120241 3.04138956,8.44353815 C5.28145783,10.0892088 7.7187751,10.9590924 10.0898273,10.9590924 C12.4609237,10.9590924 14.898241,10.0892088 17.1382209,8.44353815 C18.814506,7.2120241 19.7922691,5.98541365 19.833,5.93381526 L20.1796988,5.4954498 L19.833,5.05712851 Z M10.0897831,9.32300803 C7.97928514,9.32300803 6.2622249,7.60599197 6.2622249,5.4954498 C6.2622249,5.16478715 6.30436948,4.8437992 6.38357831,4.53752209 L5.50971888,4.39045783 C5.42410442,4.7449759 5.37869076,5.115 5.37869076,5.4954498 C5.37869076,6.85874297 5.96102811,8.08844578 6.88971084,8.94949398 C5.69424498,8.50790361 4.67769478,7.88823695 3.91047791,7.32781124 C2.95997189,6.6335743 2.24762249,5.93142972 1.84150602,5.4954498 C2.2477992,5.05933735 2.96006024,4.35728112 3.91047791,3.66308835 C4.67769478,3.10266265 5.69428916,2.48299598 6.88971084,2.04136145 L7.47593574,2.70215663 C8.16058635,2.06106426 9.08008032,1.66789157 10.0897831,1.66789157 C12.2002811,1.66789157 13.9172972,3.38490763 13.9172972,5.4954498 C13.9172972,7.60599197 12.2002811,9.32300803 10.0897831,9.32300803 Z M16.2690884,7.32781124 C15.5017831,7.88823695 14.4852771,8.50790361 13.2898554,8.94949398 C14.218494,8.08848996 14.8008313,6.85878715 14.8008313,5.49540562 C14.8008313,4.1320241 14.218494,2.90236546 13.2898554,2.04131727 C14.4853213,2.48295181 15.5017831,3.10261847 16.2690884,3.66304418 C17.2195944,4.35728112 17.9319438,5.0594257 18.3380602,5.49536145 C17.9317671,5.93156225 17.219506,6.63366265 16.2690884,7.32781124 Z" />
          <path d="M7.6541004,4.7514257 C7.58226908,4.98684337 7.5433494,5.2365743 7.5433494,5.49549398 C7.5433494,6.90181526 8.68341767,8.04192771 10.0897831,8.04192771 C11.4961044,8.04192771 12.6361727,6.90185944 12.6361727,5.49549398 C12.6361727,4.08912851 11.4961044,2.94906024 10.0897831,2.94906024 C9.40477912,2.94906024 8.78343373,3.22004016 8.32571888,3.66004016 L9.581751,5.07581526 L7.6541004,4.7514257 Z" />
        </g>
      </g>
    </g>
  </g>,
  "PasswordShowIcon"
);
