import { useCallback } from "react";
import { fbLogin } from "../../../../../services/FbApi";

export const useFacebookAuthButton = ({ onResolve }) => {
  const onClick = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      fbLogin(onResolve);
    },
    [onResolve]
  );

  return {
    onClick,
  };
};
