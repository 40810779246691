import { makeStyles } from "@material-ui/core/styles";
import { Cancel, CheckCircle } from "@material-ui/icons";
import React, { FC } from "react";
import { ValidationMarksProps } from "./types";

const useStyles = makeStyles({
  statusIcon: {
    marginRight: 7,
    fontSize: 16,
  },
  successIcon: {
    fill: "#2fc210",
  },
  failIcon: {
    fill: "#ef1f45",
  },
});

const ValidationMarks: FC<ValidationMarksProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Cancel
        className={`${classes.statusIcon} ${classes.failIcon}`}
        style={{ display: props.error ? "block" : "none" }}
      />
      <CheckCircle
        className={`${classes.statusIcon} ${classes.successIcon}`}
        style={{ display: props.success && !props.error ? "block" : "none" }}
      />
    </>
  );
};

export default ValidationMarks;
