import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <path
    d="M12.487.604C12.125.55 10.88.444 9.433.444 6.406.443 4.33 2.29 4.33 5.68V8.6H.915v3.964H4.33V22.73h4.1V12.563h3.4l.523-3.965H8.43V6.067c0-1.138.307-1.93 1.954-1.93h2.103V.605z"
    fill="#3B5EA7"
  />,
  "FacebookIcon"
);
