export const getHubUrl = (locale: string, slug?: string) => {
  return `${process.env.NX_HUB_BASE_URL}/${locale}/hub/${slug || ""}`;
};

export const getProFeaturesUrl = (locale: string, slug?: string) => {
  return `${process.env.NX_HUB_BASE_URL}/${locale}/pro-features/${slug || ""}`;
};

export const getSignUpUrl = (locale: string) => {
  switch (locale) {
    case "en-gb":
      return "https://join.ueni.com/gb/lifetime";
    case "en-us":
      return "https://join.ueni.com/lifetime";
    case "en-ca":
      return "https://join.ueni.com/ca/lifetime";
    case "en-in":
      return "https://join.ueni.com/in/lifetime/";
    case "es-es":
      return "https://join.ueni.com/es/lifetime";
    case "es-mx":
      return "https://webgratis.ueni.com/mx/pago-unico/?redirect=false";
    case "pt-br":
      return "https://webgratis.ueni.com/br/plano-vitalicio/?redirect=false";
    default:
      return `${process.env.NX_HUB_BASE_URL}/${locale}?search=true`;
  }
};

export const getHomePageUrl = (locale: string) => `${process.env.NX_HUB_BASE_URL}/${locale}`;
