export const muiBreakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1280,
};

const breakpoints = {
  xs: `${muiBreakpoints.xs}px`,
  sm: `${muiBreakpoints.sm}px`,
  md: `${muiBreakpoints.md}px`,
  lg: `${muiBreakpoints.lg}px`,
  xl: `${muiBreakpoints.xl}px`,
  xxl: "1440px",
  xxxl: "1900px",
};

export default breakpoints;
