import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { getErrorIntlKey } from "../localization/utils";
import NotificationsManager from "../services/notifications";

export const useErrorHandling = () => {
  const { formatMessage } = useIntl();

  const handleApiError = useCallback((e: AxiosError, actions?: FormikHelpers<Record<string, unknown>>) => {
    if (e?.response?.data && !e.response.data?.detail && actions) {
      actions.setErrors(e.response.data);
      return;
    }

    let errorMessage = "common.be.errors.unexpected";

    if (e?.response?.data?.detail) {
      errorMessage = e.response.data.detail;
    } else if (typeof e?.response?.data === "string") {
      errorMessage = e.response.data;
    } else if (typeof e?.response?.data === "object" && e.response.data !== null) {
      const error = Object.values(e.response.data)[0];
      if (error) {
        errorMessage = error as string;
      }
    } else if (e?.message) {
      errorMessage = e.message;
    }
    const errorKey = getErrorIntlKey(errorMessage);
    NotificationsManager.error(formatMessage({ id: errorKey }));
  }, []);

  return {
    handleApiError,
  };
};
