import * as React from "react";
import { useEffect, useMemo } from "react";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { useParams } from "react-router";
import * as sourceOfTruth from "../../../../intl/login/en_GB.json";

export type LocaleMessages = typeof sourceOfTruth;

export function importMessages(locale: string): Promise<Partial<LocaleMessages>> {
  switch (locale) {
    case "pt-br":
      return import("../../../../intl/login/pt_BR.json");
    case "es-cl":
    case "es-co":
    case "es-es":
    case "es-mx":
    case "es-pe":
      return import("../../../../intl/login/es_ES.json");
    default:
      return import("../../../../intl/login/en_GB.json");
  }
}

export const AppIntlProvider: React.FC = ({ children }) => {
  const locale = useParams()["locale"];
  const [messages, setMessages] = React.useState<Record<string, string> | null>(null);

  useEffect(() => {
    importMessages(locale).then(setMessages);
  }, [locale]);

  const intl = useMemo(
    () =>
      createIntl(
        {
          locale,
          messages: messages || {},
        },
        createIntlCache()
      ),
    [locale, messages]
  );

  return messages ? <RawIntlProvider value={intl}>{children}</RawIntlProvider> : null;
};
