import { makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../components/form/Button";
import { FormikPasswordField } from "../../../components/form/Formik";
import FormError from "../../../components/form/Formik/FormError";
import { Layout } from "../../components/Layout";
import { usePasswordRecoveryPage } from "./usePasswordRecoveryPage";

const useStyles = makeStyles({
  form: {
    padding: "0 26px",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    alignSelf: "center",
    marginTop: 35,
    marginBottom: 55,
  },
});

export const PasswordRecoveryPage = () => {
  const { formatMessage } = useIntl();
  const { initialValues, validationSchema, onSubmit } = usePasswordRecoveryPage();
  const classes = useStyles();

  return (
    <Layout title={formatMessage({ id: "passwordRecovery.reset" })}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        <Form className={classes.form}>
          <FormikPasswordField
            fullWidth
            name="password"
            label={formatMessage({ id: "passwordRecovery.newPassword" })}
          />
          <FormikPasswordField
            fullWidth
            name="passwordConfirmation"
            label={formatMessage({ id: "passwordRecovery.newPasswordConfirmation" })}
          />
          <FormError />
          <Button type="submit" className={classes.button}>
            <FormattedMessage id="passwordRecovery.submitButton" />
          </Button>
        </Form>
      </Formik>
    </Layout>
  );
};
